import { useForm } from 'react-hook-form'

import { Modify } from 'types/types'

import terms from '@common/terms'
import useAppDispatch from '@hooks/useAppDispatch'
import useProfileApiHandling from '@hooks/useProfileApiHandling'
import { Visa, VisaFormData } from '@models'
import {
  getChangedDoc,
  getSpecialConditions,
  getSpecialConditionsDoc,
} from '@redux/action-types'
import api from '@services/api'

const useVisaForm = () => {
  const { register, handleSubmit, setError, watch, setValue, ...rest } =
    useForm<Visa>({ mode: 'all' })

  const dispatch = useAppDispatch()

  const { callApi, loading } = useProfileApiHandling({
    setError,
    apiFn: api.client.updateSpecialConditions,
    onSuccessCb: () => dispatch(getSpecialConditions()),
  })

  const getDoc = () => {
    dispatch(getSpecialConditionsDoc()).then((resp: any) => {
      const href = URL.createObjectURL(resp.payload)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        'Заявления для специальных условий при проведении вступительных испытаний.pdf'
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
  const getDocTrial = () => {
    dispatch(getChangedDoc()).then((resp: any) => {
      const href = URL.createObjectURL(resp.payload)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute(
        'download',
        'Особенности проведения вступительных испытаний для инвалидов.pdf'
      )
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  return {
    watch,
    loading,
    setValue,
    getDoc,
    getDocTrial,
    ...rest,
  }
}

export default useVisaForm
