import { UploadDocKey } from '@common/uploadDocsTypes'
import { PaginatedThunk } from '@redux/types'
import { createAsyncThunk } from '@reduxjs/toolkit'
import api from '@services/api'
import { base64ToPdf } from '@utils/common'

export const getResidence = createAsyncThunk('profile/residence', async () => {
  const response = await api.client.getResidence()
  return response.data
})

export const getPersonalData = createAsyncThunk('profile/personalData', async () => {
  const response = await api.client.getPersonalData()
  return response.data
})

export const getRelationDegrees = createAsyncThunk(
  'profile/relationDegrees',
  async () => {
    const response = await api.client.getRelationDegrees()
    return response.data
  }
)

export const getNationals: PaginatedThunk = createAsyncThunk(
  'national/getNationals',
  async ({ value, page }) => {
    const response = await api.client.getNationals(value, page)
    return response.data
  }
)

export const getForeignLanguage = createAsyncThunk(
  'profile/foreignLanguage',
  async () => {
    const response = await api.client.getForeignLanguage()
    return response.data
  }
)

export const getVisa = createAsyncThunk('profile/viewerVisa', async () => {
  const response = await api.client.getVisa()
  return response.data
})
export const getSpecialConditions = createAsyncThunk(
  'profile/viewerSpecialConditions',
  async () => {
    const response = await api.client.getSpecialConditions()
    return response.data
  }
)

export const getVisaDoc = createAsyncThunk('profile/viewerVisa', async () => {
  const params = {
    typeDoc: 'visa_application_form_invitation',
  }
  const response = await api.client.getVisaDoc(params)
  return base64ToPdf(response.data.document, 'Анкета для приглашения')
})
export const getSpecialConditionsDoc = createAsyncThunk(
  'profile/viewerVisa',
  async () => {
    const params = {
      typeDoc: 'special_conditions_form_invitation',
    }
    const response = await api.client.getSpecialConditionsDoc(params)
    return base64ToPdf(
      response.data.document,
      'Бланк заявления для специальных условий при проведении вступительных испытаний'
    )
  }
)
export const getChangedDoc = createAsyncThunk('profile/viewerVisa', async () => {
  const params = {
    typeDoc: 'specifics_conducting_entrance_tests_for_disabled',
  }
  const response = await api.client.getChangedDoc(params)
  return base64ToPdf(response.data.document)
})
export const getChangedDocASP = createAsyncThunk('profile/viewerVisa', async () => {
  const params = {
    typeDoc: 'application_for_targeted_training_aspirantura',
  }
  const response = await api.client.getChangedDoc(params)
  return base64ToPdf(response.data.document)
})
export const getChangedDocBachalor = createAsyncThunk(
  'profile/viewerVisa',
  async () => {
    const params = {
      typeDoc: 'application_for_targeted_training_bachelor',
    }
    const response = await api.client.getChangedDoc(params)
    return base64ToPdf(response.data.document)
  }
)
export const getChangedDocMag = createAsyncThunk('profile/viewerVisa', async () => {
  const params = {
    typeDoc: 'application_for_targeted_training_magistr',
  }
  const response = await api.client.getChangedDoc(params)
  return base64ToPdf(response.data.document)
})
export const getChangedDocAssist = createAsyncThunk(
  'profile/viewerVisa',
  async () => {
    const params = {
      typeDoc: 'application_for_targeted_training_assistentura',
    }
    const response = await api.client.getChangedDoc(params)
    return base64ToPdf(response.data.document)
  }
)
export const getVisaCountry: PaginatedThunk = createAsyncThunk(
  'profile/visaCountries',
  async ({ value, page }) => {
    const response = await api.client.getVisaCountry(value, page)
    return response.data
  }
)

export const getVisaCity = createAsyncThunk(
  'profile/visaCities',
  async (countryId: number) => {
    const response = await api.client.getVisaCity(countryId)
    return response.data
  }
)

export const getNationalsIno: PaginatedThunk = createAsyncThunk(
  'national/getNationalsIno',
  async ({ value, page }) => {
    const response = await api.client.getNationalsIno(value, page)
    return response.data
  }
)

export const getNationalsRus: PaginatedThunk = createAsyncThunk(
  'national/getNationalsRus',
  async ({ value, page }) => {
    const response = await api.client.getNationalsRus(value, page)
    return response.data
  }
)

export const getLocations: PaginatedThunk = createAsyncThunk(
  'statements/location',
  async ({ value, page }) => {
    const response = await api.client.getLocations(value, page)
    return response.data
  }
)

export const getSubjectsRussia: PaginatedThunk = createAsyncThunk(
  'statements/subjectsRussia',

  async ({ value, page }) => {
    return await api.client.getSubjectsRussia(value, page)
  }
)

export const getKladr: PaginatedThunk = createAsyncThunk(
  'profile/kladr',
  async ({ value, page }) => {
    if (!value) {
      return { data: [] }
    }
    const response = await api.client.getKladr(value, page)
    return response.data
  }
)

export const getKladrRegion: PaginatedThunk = createAsyncThunk(
  'profile/kladrRegion',
  async ({ value, page }) => {
    const response = await api.client.getKladrRegion(value, page)
    return response.data
  }
)

export const getKladrDistrict: PaginatedThunk = createAsyncThunk(
  'profile/kladrDistrict',
  async ({ value, regionCode, page }) => {
    if (!regionCode) {
      return { data: [] }
    }
    const response = await api.client.getKladrDistrict(value, regionCode, page)
    return response.data
  }
)

export const getKladrCity: PaginatedThunk = createAsyncThunk(
  'profile/kladrCity',
  async ({ value, regionCode, districtCode, page }) => {
    if (!regionCode) {
      return { data: [] }
    }
    const response = await api.client.getKladrCity(
      value,
      regionCode,
      districtCode,
      page
    )
    return response.data
  }
)

export const getKladrStreet: PaginatedThunk = createAsyncThunk(
  'profile/kladrStreet',
  async ({ value, localityCode, page }) => {
    if (!localityCode) {
      return { data: [] }
    }
    const response = await api.client.getKladrStreet(value, localityCode, page)
    return response.data
  }
)
export const getKladrHouse: PaginatedThunk = createAsyncThunk(
  'profile/kladrHouse',
  async ({ value, streetCode, localityCode, page }) => {
    if (!streetCode) {
      return { data: [] }
    }
    const response = await api.client.getKladrHouse(
      value,
      streetCode,
      localityCode,
      page
    )
    return response.data
  }
)

export const getPhoneCodes: PaginatedThunk = createAsyncThunk(
  'national/getPhoneCodes',
  async ({ value, page }) => {
    const response = await api.client.getPhoneCodes(value, page)
    return response.data
  }
)

export const getTypeIdentityDocuments = createAsyncThunk(
  'profile/getTypeIdentityDocument',
  async () => {
    const response = await api.client.getTypeIdentityDocument()
    return response.data
  }
)

export const getTypeIdentityDocumentsRus = createAsyncThunk(
  'profile/getTypeIdentityDocumentRus',
  async () => {
    const response = await api.client.getTypeIdentityDocumentRus()
    return response.data
  }
)

export const getPreviousEduForMastersDocument = createAsyncThunk(
  'profile/previousEduForMasters',
  async () => {
    const response = await api.client.getPreviousEduForMastersDocument()
    return response.data
  }
)

export const getGenders = createAsyncThunk('profile/genders', async () => {
  const response = await api.client.getGenders()
  return response.data
})

export const getWorks = createAsyncThunk('profile/works', async () => {
  const response = await api.client.getWorks()
  return response.data
})

export const getMethodApplication = createAsyncThunk(
  'profile/methodApplication',
  async () => {
    const response = await api.client.getMethodApplication()
    return response.data
  }
)

export const getMaritalStatuses = createAsyncThunk(
  'profile/maritalStatuses',
  async (gender: number) => {
    const response = await api.client.getMaritalStatus(gender)
    return response.data
  }
)

export const getLegalRepresentative = createAsyncThunk(
  'profile/legalRepresentative',
  async () => {
    const response = await api.client.getLegalRepresentative()
    return response.data
  }
)

export const getPassportData = createAsyncThunk('profile/passportData', async () => {
  const response = await api.client.getPassport()
  return response.data
})

export const getFormEducation = createAsyncThunk(
  'profile/getFormEducation',
  async () => {
    const response = await api.client.getFormEducation()
    return response.data
  }
)

export const getLevelEducation = createAsyncThunk(
  'profile/getLevelEducation',
  async (typeEducationDocumentId?: number) => {
    const response = await api.client.getLevelEducation(typeEducationDocumentId)
    return response.data
  }
)

export const getDirectionTrainings: PaginatedThunk = createAsyncThunk(
  'profile/getLastLevelEducation',
  async ({ value, page }) => {
    const response = await api.client.getDirectionTrainings(value, page)
    return response.data
  }
)

export const getTypeDocEducation = createAsyncThunk(
  'profile/getTypeDocEducation',
  async () => {
    const response = await api.client.getTypeDocEducation()
    return response.data
  }
)

export const getPartnersList: PaginatedThunk = createAsyncThunk(
  'profile/getPartnersList',
  async ({ value, page, perPage }) => {
    const response = await api.client.getPartnersList(value, page, perPage)
    return response.data
  }
)

export const getProfileEducation = createAsyncThunk(
  'profile/getProfileEducation',
  async () => {
    const response = await api.client.getProfileEducation()
    return response.data
  }
)

export const getLevelRussianLanguageProficiency = createAsyncThunk(
  'profile/AdditionalInfo',
  async () => {
    const response = await api.client.getLevelRussianLanguageProficiency()
    return response.data
  }
)

export const getWhereStudyRussian = createAsyncThunk(
  'profile/AdditionalInfo',
  async () => {
    const response = await api.client.getWhereStudyRussian()
    return response.data
  }
)

export const getYouFindAboutPossibilityStudying = createAsyncThunk(
  'profile/AdditionalInfo',
  async () => {
    const response = await api.client.getYouFindAboutPossibilityStudying()
    return response.data
  }
)

export const getPassportKeys = createAsyncThunk(
  'profiles/getPassportKeys',
  async ({ model, unique_keys }: { model: string; unique_keys: number[] }) => {
    const response = await api.client.getIdentityDocKey({ model, unique_keys })
    return response
  }
)

export const getScenarioKeys = createAsyncThunk(
  'profiles/getScenarioKeys',
  async (model: string) => {
    const response = await api.client.getScenarioKey(model)
    return response
  }
)

export const getLevelEducationKeys = createAsyncThunk(
  'profiles/getLevelEducationKeys',
  async (model: string) => {
    const response = await api.client.getLevelEducationKeys(model)
    return response
  }
)
