import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import DownloadIcon from '@assets/images/Download.svg'
import { TRAJECTORY_RUSSIA_ID } from '@common/constants'
import { mimeTypes } from '@common/manual'
import ModeratorDataList from '@components/ModeratorDataList'
import ProfileSection from '@components/ProfileSection'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useDownloadDocument from '@hooks/useDownloadDocument'
import { getModeratorStatementsAchievementsAndPrivilegesData } from '@redux/action-types'
import {
  selectModeratorStatementsAchievementsAndPrivilegesData,
  selectModeratorStatementsIdStatus,
} from '@redux/actions/selectors'
import Checkbox from '@ui/Checkbox'
import { formatDateYYYYMMDD } from '@utils/date'

const headers = [
  'Признак',
  'Тип признака',
  'Документ',
  'Реквизиты',
  'Количество',
  'Дополнительная информация',
]

const ModeratorHallmarksPage = (scenarioKeys) => {
  const dispatch = useAppDispatch()
  const { downloadDocument } = useDownloadDocument(true)
  // TODO: закомментировал, потому что не использовалось. Что это?
  // const [lgotaAndReasonFiles, setLgotaAndReasonFiles] = useState({})

  const { id } = useParams<{ id?: string }>()

  const statement = useAppSelector(selectModeratorStatementsIdStatus)
  useEffect(() => {
    if (id) {
      dispatch(getModeratorStatementsAchievementsAndPrivilegesData(id))
    }
  }, [id])

  const hallmarks = useAppSelector(
    selectModeratorStatementsAchievementsAndPrivilegesData
  ) || {
    achievements: {
      achivment: [],
      achivmentAnketa: [],
      specialCondition: { haveRight113771Law: false },
    },
    lgota: [],
    olympiads: [],
    preemptiveRight: [],
    specialCondition: [],
  }

  // TODO: закомментировал, потому что не использовалось. Что это?
  // useEffect(() => {
  //   hallmarks.specialCondition[0]?.statementDoc?.map((item) => {
  //     setLgotaAndReasonFiles((prev) => ({
  //       ...prev,
  //       [item.userDoc.getTypeDocs.name]: item.userDocId,
  //     }))
  //   })
  // }, [hallmarks])

  const onDownload = (type: 'OLYMPIAD_CONFIRMING' | 'OLYMPIAD', docId: number) => {
    downloadDocument(type, mimeTypes.pdf, 'pdf', docId, '', Number(id))
  }

  const isCheckedSpecialConditionCheckbox = useMemo(() => {
    if (statement?.scenarioId !== scenarioKeys.level_training_spo.tdId) {
      return false
    }

    if (statement?.trajectoryId !== TRAJECTORY_RUSSIA_ID) {
      return false
    }

    if (
      hallmarks &&
      hallmarks.achievements &&
      hallmarks.achievements.specialCondition
    ) {
      return hallmarks.achievements.specialCondition.haveRight113771Law
    }
  }, [hallmarks])

  return (
    <div className={'moderator-data-list'}>
      <table className="moderator-hallmarks-table">
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {hallmarks?.achievements?.achivment?.map((item, id) => {
            return (
              <tr key={id}>
                <td>{'Достижения'}</td>
                <td>{item.achivment?.achivmentTranslate[0]?.name}</td>
                <td>{item.achivmentDoc?.achivmentDocTranslate[0]?.name}</td>
                <td>
                  {item.number}
                  <br />
                  {item.dateIssue ? formatDateYYYYMMDD(item.dateIssue) : '-'}
                </td>
                <td>{item.quantity || '-'}</td>
                <td>{item.comment || '-'}</td>
                <td>
                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() => onDownload('OLYMPIAD', item.docId)}
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
          {/*{hallmarks?.achievements?.achivmentAnketa && (*/}
          {/*  <tr key={id}>*/}
          {/*    <td>{'Достижения'}</td>*/}
          {/*    <td>*/}
          {/*      {hallmarks?.achievements?.achivmentAnketa?.achivmentTranslate?.name}*/}
          {/*    </td>*/}
          {/*    <td>*/}
          {/*      {*/}
          {/*        hallmarks?.achievements?.achivmentAnketa?.achivmentDocTranslate*/}
          {/*          ?.name*/}
          {/*      }*/}
          {/*    </td>*/}
          {/*    <td>*/}
          {/*      {hallmarks?.achievements?.achivmentAnketa?.numberEducationDocument}*/}
          {/*      <br />*/}
          {/*      {hallmarks?.achievements?.achivmentAnketa?.dateDocumentIssue*/}
          {/*        ? formatDateYYYYMMDD(*/}
          {/*            hallmarks?.achievements?.achivmentAnketa?.dateDocumentIssue*/}
          {/*          )*/}
          {/*        : '-'}*/}
          {/*    </td>*/}
          {/*    <td>{'-'}</td>*/}
          {/*    <td>{'-'}</td>*/}
          {/*    <td>*/}
          {/*      <div className="buttons">*/}
          {/*        <button*/}
          {/*          type="button"*/}
          {/*          onClick={() =>*/}
          {/*            onDownload(*/}
          {/*              'OLYMPIAD',*/}
          {/*              hallmarks?.achievements?.achivmentAnketa?.doc[0]?.id*/}
          {/*            )*/}
          {/*          }*/}
          {/*        >*/}
          {/*          <DownloadIcon />*/}
          {/*        </button>*/}
          {/*      </div>*/}
          {/*    </td>*/}
          {/*  </tr>*/}
          {/*)}*/}
          {hallmarks?.olympiads?.map((item, id) => {
            return (
              <tr key={id}>
                <td>Олимпиада</td>
                <td>{item.name}</td>
                <td>{item.docType}</td>
                <td>
                  {item.number}
                  <br />
                  {item.dateIssue}
                </td>
                <td>{item.quantity || '-'}</td>
                <td>{item.comment || '-'}</td>
                <td>
                  <div className="buttons">
                    <button
                      type="button"
                      onClick={() =>
                        onDownload('OLYMPIAD_CONFIRMING', item.userDocId)
                      }
                    >
                      <DownloadIcon />
                    </button>
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      {statement?.scenarioId === scenarioKeys.level_training_spo.tdId &&
        statement?.trajectoryId === TRAJECTORY_RUSSIA_ID && (
          <div className="moderator-hallmarks-checkbox">
            <Checkbox
              disabled
              text='Имею право на зачисление в образовательную организацию на обучение по образовательным программам среднего профессионального образования в первоочередном порядке согласно пунктам 1 - 13 части 7 статьи 71 Федерального закона "Об образовании в Российской Федерации"'
              checked={isCheckedSpecialConditionCheckbox}
            />
          </div>
        )}
    </div>
  )
}

export default ModeratorHallmarksPage
