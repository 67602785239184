import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'

import cn from 'classnames'

import {
  LEVEL_TRAINING_PREPARATION,
  LEVEL_TRAINING_SPO,
  STATUS_APPROVED,
  EGE_CODE_SPANISH,
  EGE_CODE_CHINESE,
  EGE_CODE_DEUTSCH,
  EGE_CODE_FRENCH,
  EGE_CODE_ENGLISH,
  TRIAL_CODE_FOEIGN,
} from '@common/constants'
import terms from '@common/terms'
import useAppSelector from '@hooks/useAppSelector'
import useAppWithAdditionalConditions from '@hooks/useAppWithAdditionalConditions'
import useDirectionsTrainingElement from '@hooks/useDirectionsTrainingElement'
import useTrialsTypeForm from '@hooks/useTrialsTypeForm'
import {
  ChosenTrials,
  IndexForSelect,
  SelectOptionNumber,
  SelectOptionTrials,
  SelectOptionTrialTypes,
  ViewerDirectionsListStatements,
  ViewerTabEgeData,
} from '@models'
import {
  selectBlankStatement,
  selectLevelValue,
  selectStatementsElement,
  selectTrajectoryValue,
} from '@redux/actions/selectors'
import Button from '@ui/Button'
import ReactSelect from '@ui/Select/view'

type DirectionsTrainingElementProps = {
  item: ViewerDirectionsListStatements
  index: number
  readOnly: boolean
  //chosenTrials: ChosenTrials[]
  selectNumberProps: IndexForSelect[]
  acceptId?: number
  first: number | true
  last: number | true
  //tabEge: ViewerTabEgeData | null
  isNationalityBy: boolean
}

const DirectionsTrainingElementPriorityStatement = ({
  item,
  selectNumberProps,
  acceptId,
  readOnly,
  isNationalityBy,
}: DirectionsTrainingElementProps) => {
  const { deleteElement, swapPriority, openModal } = useDirectionsTrainingElement()
  const statementEl = useAppSelector(selectStatementsElement)
  const level = useAppSelector(selectLevelValue)
  const trajectory = useAppSelector(selectTrajectoryValue)
  const { control, setValue } = useTrialsTypeForm()
  const scenarioId = level?.scenarioId ? level?.scenarioId : level?.id
  const scenariosWithoutAcceptButton = [1, 2, 3, 4, 7, 9, 10]
  const isShowAcceptStatementButton =
    scenariosWithoutAcceptButton.includes(scenarioId)

  if (item.pivot.number != null) {
    let priority: SelectOptionNumber[] = [
      {
        label: item.pivot.number,
        value: Number(item.pivot.number),
      },
    ]
    setValue(`resultEge.${item.pivot.id}.priority`, priority[0])
  } else {
    let priorityNull: SelectOptionNumber[] = [
      {
        label: '',
        value: 0,
      },
    ]
    setValue(`resultEge.${item.pivot.id}.priority`, priorityNull[0])
  }

  const { isAppWithAdditionalConditions } = useAppWithAdditionalConditions(item)

  return (
    <div
      className={cn('directions-training-element', {
        ['special-quota']: item.specialQuota,
      })}
    >
      <div className={'row'}>
        <div>
          <h2>{item.directionTraining?.translate[0]?.name || '-'} </h2>
        </div>
      </div>

      <div className={'row'}>
        <div className={'column'}>
          {item.specialQuota ? (
            <p>
              <b>{terms.SEPARATE_QUOTA}</b>
            </p>
          ) : item.benefits ? (
            <p>
              <b>{terms.SPECIAL_QUOTA}</b>
            </p>
          ) : null}
          <p>
            {terms.MODE_OF_STUDY}:{' '}
            <b>{item.formEducation?.formEducationTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.FORM_PAYMENT_ID}:{' '}
            <b>{item.formPayment?.translate[0]?.name || '-'}</b>
          </p>
        </div>
        <div className={'column'}>
          <p>
            {terms.LEVEL_OF_TRAINING}:{' '}
            <b>{item.trainingLevel?.trainingLevelTranslate[0]?.name || '-'}</b>
          </p>
          <p>
            {terms.EDUCATIONAL_PROGRAM}:{' '}
            <b>{item.profile?.translate[0]?.name || '-'}</b>
          </p>
        </div>
      </div>
      <div className="row">
        <div className={'column'}>
          {isAppWithAdditionalConditions ? (
            <p>
              Данная специальность реализуется в следующих подразделениях: на
              факультете географии, факультете биологии, факультете химии, факультете
              безопасности жизнедеятельности, факультете математики, филологическом
              факультете; в институте физики, институте детства, институте русского
              языка как иностранного, институте иностранных языков, институте музыки,
              театра и хореографии, институте информационных технологий и
              технологического образования, институте истории и социальных наук.
            </p>
          ) : (
            <p>
              {terms.SUBDIVISION}:{' '}
              <b>{item.faculty?.facultyTranslate[0].name || '-'}</b>
            </p>
          )}
        </div>
      </div>
      <div className="row row_flex">
        <div className="directions-training-element__control-btn-container directions-training-element-priority">
          <div className={'directions-training-element__control-btn-container_btn'}>
            <div className="priority-number directions-training-element-priority">
              <div className={'column-flex'}>
                <Controller
                  name={`resultEge.${item.pivot.id}.priority`}
                  control={control}
                  render={({ field }) => (
                    <ReactSelect<SelectOptionNumber>
                      options={selectNumberProps}
                      placeholder={terms.PRIORITY_GROUP}
                      className="result-exam__element_field"
                      markRequired={true}
                      styles={{
                        menu: (base) => ({ ...base, minWidth: '200px' }),
                      }}
                      {...(field as unknown)}
                      //  isClearable={true}
                      onChange={(e) => {
                        swapPriority(item.id, e.value, true)
                        if (e) {
                          field.onChange(e)
                        }
                      }}
                      // isDisabled={readOnly}
                    />
                  )}
                />
              </div>
              {/*{'Номер приоритета зачисления '}*/}
              {/*{item ? console.log(item.pivot) : 'console.log(item.pivot)'}*/}
              {/*{`${*/}
              {/*  item.pivot.number != null*/}
              {/*    ? item.pivot.number*/}
              {/*    : 'устанавливается стрелками'*/}
              {/*}`}*/}
            </div>

            {/*<CardForPriorityDownloadFile*/}
            {/*  flag={*/}
            {/*    formValues?.findIndex(*/}
            {/*      (el) => el.accept === false && el.statementCheckboxId !== 6*/}
            {/*    ) === -1*/}
            {/*  }*/}
            {/*/>*/}

            {/*<Button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => swap(item.id, first, true)}*/}
            {/*  disabled={readOnly || first === true}*/}
            {/*>*/}
            {/*  <ArrowUp />*/}
            {/*</Button>*/}
            {/*<Button*/}
            {/*  type={'button'}*/}
            {/*  onClick={() => swap(item.id, last, true)}*/}
            {/*  disabled={readOnly || last === true}*/}
            {/*>*/}
            {/*  <ArrowDown />*/}
            {/*</Button>*/}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DirectionsTrainingElementPriorityStatement
