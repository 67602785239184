import React from 'react'
import { useLocation } from 'react-router-dom'

import terms from '@common/terms'
import LevelStatementsList from '@components/LevelStatementsList'
import LevelStatementsPaymentFormList from '@components/LevelStatementsPaymentFormList'
import useLevelPaymentPage from '@hooks/useLevelPaymentFormPage'
import Button from '@ui/Button'

const LevelPaymentFormPage = () => {
  const location = useLocation()

  const scenarioPaymentType: any = location.state

  const { onSubmit } = useLevelPaymentPage(scenarioPaymentType.scenario)

  return (
    <main className={'level-page'}>
      <LevelStatementsPaymentFormList
        scenarioPaymentType={scenarioPaymentType.scenario}
      />
      <Button onClick={() => onSubmit()} theme={'success'}>
        {terms.SAVE_AND_NEXT}
      </Button>
    </main>
  )
}

export default LevelPaymentFormPage
