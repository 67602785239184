import React, { useMemo } from 'react'

import { DATE_ASP_SHOW_TESTS } from '@common/constants'
import EntranceExamsForm from '@components/EntranceExamsForm'
import FillingStatementsForm from '@components/FillingStatementsForm'
import useAppSelector from '@hooks/useAppSelector'
import useFillingStatementsRoutesContext from '@hooks/useFillingStatementsRoutesContext'
import { selectStatementsElement } from '@redux/actions/selectors'

const EntranceExamsPage = (scenarioKeys) => {
  const showAspTestsMessage = useMemo(() => {
    const currentTime = new Date()
    const dateAspShowTests = new Date(DATE_ASP_SHOW_TESTS)
    return currentTime >= dateAspShowTests
  }, [DATE_ASP_SHOW_TESTS])

  const { onNextPageNavigate } = useFillingStatementsRoutesContext()
  const statement = useAppSelector(selectStatementsElement)
  const isVisibleHints =
    (statement?.trajectoryId === 1 &&
      statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId) ||
    (statement?.trajectoryId === 2 &&
      statement?.scenarioId !== scenarioKeys.asperatnuta_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_preparation.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_bachelor.tdId &&
      statement?.scenarioId !== scenarioKeys.magistra_rf.tdId &&
      statement?.scenarioId !== scenarioKeys.magistra_ino.tdId &&
      statement?.scenarioId !== scenarioKeys.level_training_bachelor_ino.tdId)

  const isVisibleHintsINO =
    (statement?.trajectoryId === 2 &&
      statement?.scenarioId === scenarioKeys.level_training_bachelor_ino.tdId) ||
    (statement?.trajectoryId === 2 &&
      statement?.scenarioId === scenarioKeys.magistra_ino.tdId)

  return (
    <FillingStatementsForm
      className="entr-exams__form"
      onSubmit={(e) => {
        e.preventDefault()
        onNextPageNavigate()
      }}
    >
      {(statement?.scenarioId === scenarioKeys.asperatnuta_rf.tdId ||
        statement?.scenarioId === scenarioKeys.level_training_preparation.tdId) && (
        <div>
          {statement?.scenarioId ===
            scenarioKeys.level_training_preparation.tdId && (
            <p className={'mb-12'}>
              С результатами сдачи вступительных испытаний можно ознакомиться в
              разделе Новости - “Результаты сдачи вступительных испытаний 2024/2025»{' '}
              <a
                className={'link_to_information'}
                href="https://herzen.spb.ru/about/struct-uni/contr/upravlenie-organizatsii-dissertatsionnykh-issledovaniy/otdel-aspirantury/"
                target={'_blank'}
              >
                по ссылке
              </a>
              .
            </p>
          )}

          {showAspTestsMessage && (
            <p className={'mb-12'}>
              С результатами сдачи вступительных испытаний можно ознакомиться в
              разделе Новости - «Результаты сдачи вступительных испытаний 2024/2025»{' '}
              <a
                href="https://herzen.spb.ru/about/struct-uni/contr/upravlenie-organizatsii-dissertatsionnykh-issledovaniy/otdel-aspirantury/"
                target="_blank"
                style={{ color: 'blue' }}
              >
                по ссылке
              </a>
              .
            </p>
          )}

          <p className={'mb-12'}>
            В соответствии с Правилами приема 2024, поступающие, не сдавшие
            вступительное испытание и (или) не набравшие минимальное количество
            баллов, равное трём, при сдаче вступительного испытания, не допускаются к
            сдаче следующего вступительного испытания и выбывают из конкурса".
          </p>
          <p className={'mb-24'} style={{ fontWeight: 'bold' }}>
            При сдачи вступительного испытания в очной форме для прохода на
            территорию СПбГУ, Вам необходимо предъявить документ, удостоверяющий
            личность.
          </p>
        </div>
      )}

      <EntranceExamsForm {...scenarioKeys} />

      {isVisibleHints && (
        <div className="entr-exams__texts">
          <a style={{ color: 'blue' }}>
            Информация об особенностях проведения вступительных испытаний
          </a>
          <p>
            При возникновении технического сбоя во время вступительного испытания
            обращаться по e-mail:
            {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
          <p>
            Заявление на перенос вступительного испытания на резервный день
            отправлять по e-mail: {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
          <p>
            Апелляционное заявление по результатам вступительного испытания
            отправлять по e-mail: {/*<a style={{ color: 'blue' }} href="mailto:">*/}
            {/*  e-mail*/}
            {/*</a>*/}
          </p>
        </div>
      )}

      {isVisibleHintsINO && (
        <div className="entr-exams__texts">
          При возникновении технического сбоя во время вступительного испытания
          обращаться по e-mail:{' '}
          <a href="mailto:admission@herzen.spb.ru" style={{ color: 'blue' }}>
            admission@herzen.spb.ru
          </a>
        </div>
      )}
    </FillingStatementsForm>
  )
}

export default EntranceExamsPage
