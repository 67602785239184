import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { NavLink, Outlet, useParams } from 'react-router-dom'

import TrashIcon from '@assets/images/Trash.svg'
import {
  ROLE_ADMIN,
  ROLE_MODERATOR,
  ROLE_SUPPORT,
  STATUS_APPROVED,
  STATUS_DIRECTION_EDITING,
  STATUS_EDIT_DIRECTION,
  STATUS_SEND_EDITING,
  STATUS_USER_EDIT,
  STATUS_WORK,
} from '@common/constants'
import terms from '@common/terms'
import ChatWidget from '@components/ChatWidget'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import useModeratorStatementIdForm from '@hooks/useModeratorStatementIdForm'
import useModeratorStatementsIdPageInitialization from '@hooks/useModeratorStatementsIdPageInitialization'
import useModeratorStatementsRoutesContext, {
  ModeratorStatementsRoutesContextProvider,
} from '@hooks/useModeratorStatementsRoutesContext'
import useSubmitFormContext, {
  SubmitFormContextProvider,
} from '@hooks/useSubmitFormContext'
import { SelectOption } from '@models'
import SidenavPage from '@pages/SidenavPage'
import { showModal } from '@redux/action-types'
import { selectModeratorStatementsPersonalData } from '@redux/actions/selectors'
import Breadcrumbs from '@ui/Breadcrumbs'
import Button from '@ui/Button'
import Loader from '@ui/Loader'
import Select from '@ui/Select'
import Textarea from '@ui/Textarea'

const ModeratorStatementsIdPage = () => {
  const { control, setValue } = useForm<{
    moderatorId: number
  }>({ mode: 'all' })

  const statement = useAppSelector(selectModeratorStatementsPersonalData) || {}

  const {
    fields,
    inWork,
    sendUser,
    reject,
    approve,
    loading,
    revokeApprove,
    changeModerator,
  } = useModeratorStatementIdForm()

  const { moderatorsListOptions, roleId, status, statusCode, statusAdmin } =
    useModeratorStatementsIdPageInitialization({ setValue })

  const dispatch = useAppDispatch()
  const { id } = useParams<{ id?: string }>()

  if (!status) {
    return <Loader />
  }

  return (
    <div className={'moderator-statements-id-page'}>
      <div className={'moderator-statements-id-page__header'}>
        <Breadcrumbs
          link={'/admin/statements'}
          className={'moderator-statements-id-page__header-title'}
          title={'Проверка заявления'}
        />
        {roleId === ROLE_ADMIN &&
          (statusCode && statusCode >= STATUS_WORK ? (
            <Controller
              name="moderatorId"
              control={control}
              render={({ field }) => (
                <Select<SelectOption>
                  className={'moderator-statements-id-page__header-select'}
                  {...(field as unknown)}
                  options={moderatorsListOptions}
                  onChange={(e) => {
                    if (e) {
                      field.onChange(e)
                      changeModerator(e.value)
                    }
                  }}
                  placeholder={'Модератор'}
                />
              )}
            />
          ) : (
            <Button
              className={'moderator-statements-id-page__header-btn'}
              onClick={inWork}
            >
              Взять в работу
            </Button>
          ))}
        {roleId === ROLE_MODERATOR &&
          (statusCode && statusCode < STATUS_WORK ? (
            <Button
              className={'moderator-statements-id-page__header-btn'}
              onClick={inWork}
            >
              Взять в работу
            </Button>
          ) : (
            <Button className={'moderator-statements-id-page__header-btn'}>
              В работе
            </Button>
          ))}
      </div>
      <SubmitFormContextProvider>
        <ModeratorStatementsRoutesContextProvider>
          <SidenavPage
            useSubmitFormHook={useSubmitFormContext}
            useHooks={useModeratorStatementsRoutesContext}
            title=""
            next={false}
          >
            <Outlet />
          </SidenavPage>
          {statement && <ChatWidget admin id={status?.users?.id} />}
        </ModeratorStatementsRoutesContextProvider>
      </SubmitFormContextProvider>

      {roleId !== ROLE_SUPPORT && (
        <div className="moderator-contract-info">
          <div className="moderator-contract-info__block">
            <h2>
              Статус заявки:{' '}
              <span>{status?.statusStatement?.translate[0]?.name || '-'}</span>
            </h2>
          </div>
          <Textarea {...fields.comment} placeholder="Комментарий" />
          {(roleId === ROLE_MODERATOR || roleId === ROLE_ADMIN) && (
            <div className="moderator-contract-info__footer">
              <div className="moderator-contract-info__footer-btn">
                {roleId === ROLE_ADMIN && (
                  <NavLink to={'/admin/users/' + status?.users?.id || ''}>
                    <Button type={'button'}>
                      Зайти в личный кабинет пользователя
                    </Button>
                  </NavLink>
                )}
                {roleId === ROLE_ADMIN && (
                  <Button
                    type={'button'}
                    className="statements-container__element-btn-panel_icon"
                    onClick={(event) => {
                      event.stopPropagation()
                      dispatch(
                        showModal({
                          name: 'IS_DELETE_APPLICATION',
                          data: {
                            statementId: id,
                            content: terms.STATEMENT,
                          },
                        })
                      )
                    }}
                  >
                    <TrashIcon />
                  </Button>
                )}
              </div>

              {/* Перевести статус заявления на 'В работе' */}
              {(statusCode === STATUS_EDIT_DIRECTION ||
                statusCode === STATUS_DIRECTION_EDITING ||
                statusCode === STATUS_SEND_EDITING ||
                statusCode === STATUS_USER_EDIT) && (
                <div className="moderator-contract-info__footer-btn">
                  <Button type="button" theme="success" onClick={inWork}>
                    Перевести статус заявления на 'В работе'
                  </Button>
                </div>
              )}

              {(statusCode === STATUS_WORK ||
                //3\3 Третья часть костыля по перезаливаю заявок в 1с
                // Первая и вторая часть на бэке todo
                statusCode === STATUS_APPROVED ||
                // конец костыля
                statusCode === STATUS_USER_EDIT ||
                statusCode === STATUS_DIRECTION_EDITING) && (
                <div className="moderator-contract-info__footer-btn">
                  <Button
                    type={'button'}
                    onClick={reject}
                    className="moderator-contract-info__footer-btn-reject"
                  >
                    Отказать
                  </Button>

                  {statusCode !== STATUS_DIRECTION_EDITING &&
                    statusCode !== STATUS_APPROVED && (
                      <Button type={'button'} onClick={sendUser}>
                        Отправить на редактирование
                      </Button>
                    )}

                  {loading ? (
                    <Button
                      disabled={true}
                      type={'button'}
                      onClick={approve}
                      theme={'success'}
                    >
                      Идет обмен с 1С
                    </Button>
                  ) : (
                    <Button type={'button'} onClick={approve} theme={'success'}>
                      Одобрить
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default ModeratorStatementsIdPage
