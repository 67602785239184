import React from 'react'

import terms from '@common/terms'
import LevelListElementPayment from '@components/LevelStatementsPaymentFormList/LevelListElementPayment'
import useAppDispatch from '@hooks/useAppDispatch'
import useAppSelector from '@hooks/useAppSelector'
import { setLevelValue } from '@redux/action-types'
import { selectLevelValue } from '@redux/actions/selectors'

type PaymentScenarioProps = {
  scenarioPaymentType: Array<any>
}

const LevelStatementsPaymentFormList = ({
  scenarioPaymentType,
}: PaymentScenarioProps) => {
  let active = useAppSelector(selectLevelValue)

  //const {scenarioPaymentType} = use
  const dispatch = useAppDispatch()

  const setActive = (value) => {
    dispatch(setLevelValue(value))
  }

  const levelDataList = scenarioPaymentType //useAppSelector(selectLevelList)
  if (!active.paymentType) {
    active = levelDataList[0]
  }

  const renderLevelList = () => {
    const countMainBlocks = levelDataList?.reduce(
      (acc, { name }) =>
        name.includes(terms.MASTER_PROGRAM) ||
        name.includes(terms.SPECIALIST_PROGRAM)
          ? acc + 1
          : acc,
      0
    )

    switch (countMainBlocks) {
      case 1:
        return levelDataList?.map(({ id, name, paymentType, scenarioId }, index) => (
          <LevelListElementPayment
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
            paymentType={paymentType}
            scenarioId={scenarioId}
            className={
              name.includes(terms.MASTER_PROGRAM) ||
              name.includes(terms.SPECIALIST_PROGRAM)
                ? 'one-block'
                : null
            }
          />
        ))
      case 2:
        return levelDataList?.map(({ id, name, paymentType, scenarioId }, index) => (
          <LevelListElementPayment
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
            paymentType={paymentType}
            scenarioId={scenarioId}
            className={
              name.includes(terms.MASTER_PROGRAM) ||
              name.includes(terms.SPECIALIST_PROGRAM)
                ? 'two-blocks'
                : null
            }
          />
        ))
      default:
        return levelDataList?.map(({ id, name, paymentType, scenarioId }, index) => (
          <LevelListElementPayment
            key={index}
            setActive={setActive}
            active={id === active.id}
            id={id}
            name={name}
            paymentType={paymentType}
            scenarioId={scenarioId}
            className={
              name.includes(terms.MASTER_PROGRAM) ||
              name.includes(terms.SPECIALIST_PROGRAM)
                ? 'two-blocks'
                : null
            }
          />
        ))
    }
  }

  return <section className="level-list">{renderLevelList()}</section>
}

export default LevelStatementsPaymentFormList
